






































import { Component, Vue, Inject } from "vue-property-decorator";
import { SnackbarOptions } from "@/models/form";
import EmailTemplateService from "@/services/email-template-service";
import EcSnackBar from "@/components/common/ec-snackbar.vue";
import { EmailTemplate } from "@/models/email-templates";
import { TableHeader } from "@/components/layout/models/table";
import Card from "@/components/material/Card.vue";
import EmailTemplateDeleteDialog from "@/components/email-templates/email-template-delete-dialog.vue";

@Component({
  components: {
    EcSnackBar,
    Card,
    EmailTemplateDeleteDialog
  }
})
export default class EmailTemplatesView extends Vue {
  @Inject() EmailTemplateService!: EmailTemplateService;

  headers: TableHeader[] = [];
  templates: EmailTemplate[] = [];
  loading = true;
  totalItems = 0;
  snackbarOptions: SnackbarOptions = EcSnackBar.makeDefaultOptions();
  confirmDeleteDialog = false;
  itemToDelete: EmailTemplate = {} as EmailTemplate;

  itemsPerPage = 15;

  mounted() {
    this.headers = [
      {
        text: "Name",
        value: "name",
        sortable: true,
        sortDirection: "asc"
      },
      {
        text: "Subject",
        value: "subject",
        sortable: true
      },
      {
        text: "",
        value: "actions",
        width: "1px"
      }
    ];
    this.loadPage();
  }

  async loadPage() {
    this.loading = true;

    try {
      const list = await this.EmailTemplateService.list();
      this.loading = false;
      this.totalItems = list.page.totalElements;
      this.templates = list._embedded["email-templates"];
    } catch (error) {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as string);
      this.loading = false;
    }
  }

  isAddbuttonDisabled() {
    return this.templates.length >= 15;
  }

  AddButtonToolTip() {
    if (this.isAddbuttonDisabled()) {
      return "Cannot add more than 15 email templates";
    }
    return "Add a new email template";
  }

  openAddPage() {
    this.$router.push({ name: "email-template-create" }).catch(() => {
      return true;
    });
  }

  openConfirmDeleteDialog(item: EmailTemplate) {
    this.confirmDeleteDialog = true;
    this.itemToDelete = item;
  }

  async deleteEmailTemplate() {
    this.confirmDeleteDialog = false;

    try {
      await this.EmailTemplateService.delete(this.itemToDelete.id);
      await this.loadPage();
      this.snackbarOptions = EcSnackBar.makeSuccessfulOptions("Email Template Deleted");
    } catch (error) {
      this.snackbarOptions = EcSnackBar.makeUnsuccessfulOptions(error as string);
    }
  }
}
